import React , {useContext} from "react"
import { Container, Button, lightColors } from "react-floating-action-button";
import { MenuIcon, QuestionMarkCircleIcon, SearchIcon, ShoppingBagIcon, XIcon } from '@heroicons/react/outline'
import Context from "views/Context/Context";
import Assistance from "./views/ligne-dassistance.svg"


const FloatingButton = ({showButton , handleClick})=>{
    const { panelContext, setPanelContext } = useContext(Context)
    return (

        <Container fluid className="floatingBottomStore mb-3">
        {showButton && (
            <Button
            tooltip="Réclamation"
            className="bg-red-400"
            styles={{ color: 'white' }}
            onClick={handleClick}
            >
         {/* <Assistance /> */}
         <img src={Assistance} style={{height:"35px" , width:"35px"}}/>
       
            </Button>
        )}
        </Container>
    )
}

export default FloatingButton