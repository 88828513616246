import React from 'react';


const Maintenance = () => {
  return (


    <div className='container__'> 
    <div className="min-h-screen flex items-center justify-center bg-gray-100">

<div className="bg-white p-8 rounded-lg shadow-lg text-center">
<div className="animation_main">
          <div className="one_main spin-one"></div>
          <div className="two_main spin-two"></div>
          <div className="three_main spin-one"></div>
        </div>

        <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#2d3748', marginBottom: '0.5rem' }}>En maintenance</h1>
<p style={{ color: '#4a5568', marginBottom: '1rem', fontSize: '1.25rem', paddingBottom: '1rem' }}>
  Notre site est actuellement en maintenance. Nous reviendrons bientôt. Merci de votre compréhension.
</p>
<a href="/login" style={{ color: '#f56565', textDecoration: 'underline', paddingBottom: '1rem', display: 'block' }}>
  Ou essayez de vous connecter plus tard
</a>

 
</div>

</div>
    
    </div>
  



  );
};

export default Maintenance;