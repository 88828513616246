import axios from "axios";
import {
  BASE_URL,
  SECRET_KEY_ENCRYPT,
  ACCOUNT_CAISSE_POLEPARA,
  ACCOUNT_CAISSE_SAID,
  ADMIN_PASSWORD,
} from "./utils/config";
import Cryptr from "cryptr";
import ls from "local-storage";
import md5 from "react-native-md5";

const Connexion = {
  isAuthenticated: false,
  async authenticate(params) {
    let cryptr = new Cryptr(SECRET_KEY_ENCRYPT);

    let url = BASE_URL + "auth";

    let finalToken = await axios
      .post(url, params)
      .then((response) => {
    
        let token = response.data.token;
        let flagcataloge = response.data.flagcataloge;
        let afficher_catalogue = response.data.afficher_catalogue;
        let afficher_tableau_de_bord = response.data.afficher_tableau_de_bord;
        let afficher_client = response.data.afficher_client;
        let afficher_gestion_de_stock = response.data.afficher_gestion_de_stock;
        let afficher_bon_commande = response.data.afficher_bon_commande;
        let afficher_inventaire = response.data.afficher_inventaire;
        let afficher_lalivraison = response.data.afficher_lalivraison;
        let afficher_facturation = response.data.afficher_facturation;
        let afficher_facture_fournisseur =
          response.data.afficher_facture_fournisseur;
        let afficher_devis = response.data.afficher_devis;
        let id_account_caisse = response.data.id_account_caisse;
        let afficher_deliverect = response.data.afficher_deliverect;
        let afficher_cheques = response.data.afficher_cheques;
        let afficher_privileges = response.data.afficher_privileges;
        let groupeclients = response.data.groupeclients;
        let reglesdereduction = response.data.reglesdereduction;
        let reglesdeprix = response.data.reglesdeprix;
        let channel_manager = response.data.channel_manager;
        let client_commun = response.data.client_commun

        if (token !== "") {
          ls.set("connected", true);
          this.isAuthenticated = true;
        } else {
          // error connexion
          ls.set("connected", false);
          this.isAuthenticated = false;
        }

        // Store the token
        ls.set("redirect", "");
        ls.set("email", params.login);
        ls.set("password", params.password);
        ls.set("flagcataloge", flagcataloge);
        ls.set("afficher_catalogue", afficher_catalogue);
        ls.set("afficher_tableau_de_bord", afficher_tableau_de_bord);
        ls.set("afficher_client", afficher_client);
        ls.set("afficher_gestion_de_stock", afficher_gestion_de_stock);
        ls.set("afficher_bon_commande", afficher_bon_commande);
        ls.set("afficher_inventaire", afficher_inventaire);
        ls.set("afficher_lalivraison", afficher_lalivraison);
        ls.set("afficher_facturation", afficher_facturation);
        ls.set("afficher_facture_fournisseur", afficher_facture_fournisseur);
        ls.set("afficher_devis", afficher_devis);
        ls.set("afficher_deliverect", afficher_deliverect);
        ls.set("afficher_cheques", afficher_cheques);
        ls.set(
          "afficher_privileges",
          afficher_privileges == undefined ? 0 : afficher_privileges
        );
        ls.set("current_account_caisse", cryptr.encrypt(id_account_caisse));
        ls.set("token", token);
        ls.set("password", cryptr.encrypt(params.password));
        ls.set("onglet", "1");
        ls.set("number_page_article", 1);
        ls.set("number_page_categorie", 1);
        ls.set("searchText", "");
        ls.set("caissename", "");
        ls.set("groupeclients", groupeclients);
        ls.set("reglesdereduction", reglesdereduction);
        ls.set("reglesdeprix", reglesdeprix);
        ls.set("channel_manager", channel_manager);
        ls.set("client_commun",client_commun)

        if (id_account_caisse == ACCOUNT_CAISSE_POLEPARA) {
          ls.set("polepara", 1);
        } else {
          ls.set("polepara", 0);
        }
        if (id_account_caisse == ACCOUNT_CAISSE_SAID) {
          ls.set("cheque", 1);
        } else {
          ls.set("cheque", 0);
        }
        return token;
      })
      .then((token) => {
        return token;
      })
      .catch((error) => {
        
        console.log("Error during authentication:", error);

        if (error.response) {
          // Server responded with a status code outside the 2xx range
          if (error.response.status === 500) {
            // Check if the error message indicates incorrect credentials
            if (error.response.data.status === "login ou mot de passe incorrect !!!") {
              alert("Incorrect login or password.");
            } else {
              // Other server-side issues, like CORS
              window.location.href = "/underMaintenance";
            }
          } else {
            // Handle other response status codes
            window.location.href = "/underMaintenance";
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received, possible CORS issue.");
          window.location.href = "/underMaintenance";
        } else {
          // Other errors
          console.error("Error setting up request:", error.message);
          window.location.href = "/underMaintenance";
        }
    
        this.isAuthenticated = false;
        return null;
      });

    return finalToken;
  },
  signout(cb) {
    this.isAuthenticated = false;
    localStorage.clear();
    ls.set("passwordmodal", ADMIN_PASSWORD);
    ls.set("glovo", 1);
    window.location.reload(true);
    return this.isAuthenticated;
  },
};

export default Connexion;
